import React, { Component } from "react";
import "./Home.css";
import Overview from "./Overview/Overview";

class Home extends Component {

    render() {
        return (
          <div>
            <div className="index-container">
              <div>
                <Overview />
              </div>
            </div>
          </div>
        );
    }
}

export default Home;