import React, { Component } from "react";
import "./Privacy-Policy.css";

class UsageTerms extends Component {

    render() {
        return (
          <div>
            <div>
              <main className="main-body">
                <div className="privacy-policy-container">
                  <div width="2" className="privacy-policy-content-section">
                    <div className="privacy-policy-container">
                      <section className="privacy-policy-heading">
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h2 className="privacy-policy-heading-text">
                            Carovia Terms of Use{" "}
                            <sup>
                              {" "}
                              (boooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooring.)
                            </sup>
                          </h2>
                          <br />
                          <p className="timestamp-label">
                            Last Updated: 27 March, 2024
                          </p>
                          <hr />
                          <p className="legal-text">
                            This Privacy Policy describes how LD Cipher LTD
                            ('we', 'us', or 'our') collects, uses, and discloses
                            personal information when you use Carovia (the
                            “App'). The App is providing users with information
                            about their location relative to toll zones and also
                            tracks history of their visits into these zones..
                          </p>
                        </div>
                      </section>
                      <section
                        title="1. Usage of Services"
                        id="relationships-with-service-providers"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            1. Usage of Services
                          </h3>
                          <p className="legal-text">
                            1.1 Application Functionality: The App provides
                            users with various features including:
                          </p>
                          <ul className="legal-list">
                            <li>
                              Reminders and details of MOT history and road tax
                              expiry dates for their vehicles.
                            </li>
                            <li>
                              Tracking of UK charge zones, including city
                              charges and tolls, for vehicles added to the App's
                              garage. Users can select and track charge zones
                              for their vehicles, and the App will note their
                              entry and exit from actively tracked charge zones.
                              The App will also provide timely payment reminders
                              to ensure users pay before receiving fines.
                            </li>
                          </ul>
                          <p className="legal-text">
                            1.2 Application Functionality: The Carovia mobile
                            application provides users with features such as
                            reminders and details of MOT history, road tax
                            expiry dates, and tracking of UK charge zones for
                            vehicles. By using the App, you acknowledge and
                            accept the functionality provided.
                          </p>
                          <p className="legal-text">
                            By agreeing to these terms, you acknowledge and
                            accept the functionality provided by the App. You
                            use the data and information provided by the App at
                            your own risk, and we are not liable for any
                            consequences arising from the use of this
                            information.
                          </p>
                        </div>
                      </section>
                      <section
                        title="2. Subscription Services"
                        id="childrens-privacy"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            2. Subscription Services
                          </h3>
                          <p className="legal-text">
                            2.1 Subscription Terms: The use of the Carovia
                            mobile application requires a subscription. Users
                            will have access to a free trial period upon initial
                            download and installation of the App.
                          </p>
                          <p className="legal-text">
                            2.2 Payment Terms: After the free trial period ends,
                            users must subscribe to continue using the App. By
                            subscribing to the Carovia mobile application, you
                            agree to the payment and billing terms outlined
                            below.
                          </p>
                          <p className="legal-text">
                            (a) Payment Method: Subscriptions will be billed
                            automatically to your chosen payment method (e.g.,
                            Apple Account) at the start of each subscription
                            period (e.g., monthly, annually).
                          </p>
                          <p className="legal-text">
                            (b) Cancellation Policy: You may cancel your
                            subscription at any time. Cancellation will go into
                            effect at the end of your current billing cycle, and
                            no refunds will be provided for termination or
                            cancellation. Instructions for cancellation can be
                            found in the settings of your chosen payment method
                            (e.g., Apple App Store).
                          </p>
                          <p className="legal-text">
                            (c) Fee Changes: We reserve the right to change
                            subscription prices at any time. You will be
                            notified of any pricing changes in advance. If you
                            do not wish to pay the new prices, you may cancel
                            your subscription before the changes take effect.
                          </p>
                          <p className="legal-text">
                            (d) Free Trial: Users are entitled to a free trial
                            period upon initial download and installation of the
                            App. The duration of the free trial period will be
                            specified within the App. At the end of the free
                            trial period, users must subscribe to continue using
                            the App.
                          </p>
                        </div>
                      </section>
                      <section title="3. Contact Us" id="childrens-privacy">
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            3. Contact Us
                          </h3>
                          <p className="legal-text">
                            If you have any questions regarding the App or our
                            practices, please contact us via email at
                            support@carovia.co.uk
                          </p>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        );
    }
}

export default UsageTerms;
