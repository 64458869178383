import React from "react";
import "normalize.css";
import "./Constants.css"

import Home from "./components/Home/Home";


import { Routes, BrowserRouter, Route } from "react-router-dom";
import PrivacyPolicy from "./components/Privacy-Policy/Privacy-Policy";
import UsageTerms from "./components/Terms of Use/UsageTerms";


function App() {
  return (
    <main>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/carovia-privacy-policy"
          element={<PrivacyPolicy />}
        />
        <Route
          exact
          path="/carovia-terms-of-use"
          element={<UsageTerms />}
        />
      </Routes>
    </main>
  );
}

export default App;
