import React, { Component } from "react";
import "./Overview.css";

class Overview extends Component {
    render() {
        return (
          <div className="overview-container">
            <div className="overview-header">
              <h1>LD CIPHER</h1>
              <h3>UNLOCKING INNOVATION.</h3>
            </div>
            <div className="overview-content">
              <br />
              <h2>
                We're on a mission to create products that serve humanity.
              </h2>
              <p className="subheading-text">
                LD CIPHER is a indie development company, driven by a sincere
                passion for creating software that genuinely benefits its users.
                With a strong focus on simplicity and user impact, our goal is
                to offer solutions that make a positive difference in people's
                lives.
                <br />
                <br />
                We're excited to have you join us on our transformative journey
                ahead and remain committed to learning and growing with each
                step.
              </p>
              <br /> <br /> <br />
              <h3>Reach Out for Support</h3>
              <p>
                Let us know how we can assist you. Whether you have questions
                about our products, need technical guidance, or have suggestions
                for improvement, your feedback is invaluable to us.
                <br />
                <br />
                Your satisfaction is our priority, and we're here to support you
                every step of the way.
              </p>
              <div className="body-button">
                <div className="body-btn-circle"></div>
                <a href="mailto:pengboi@ldcipher.co.uk">Contact Us</a>
              </div>
            </div>
            <div className="overview-subcontent">
              <div>
                <h3 className="subcontent-header">
                  <span>Upcoming Projects</span>
                </h3>
                <ul className="subcontent-list">
                  <li>
                    <a href="/">Carovia</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
    }
}




export default Overview;