import React, { Component } from "react";
import "./Privacy-Policy.css";

class PrivacyPolicy extends Component {

    render() {
        return (
          <div>
            <div>
              <main className="main-body">
                <div className="privacy-policy-container">
                  <div width="2" className="privacy-policy-content-section">
                    <div className="privacy-policy-container">
                      <section className="privacy-policy-heading">
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h2 className="privacy-policy-heading-text">
                            Carovia Privacy Policy{" "}
                            <sup>
                              {" "}
                              (boooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooring.)
                            </sup>
                          </h2>
                          <br />
                          <p className="timestamp-label">
                            Last Updated: 22 March, 2024
                          </p>
                          <hr />
                          <p className="legal-text">
                            This Privacy Policy describes how LD Cipher LTD
                            ('we', 'us', or 'our') collects, uses, and discloses
                            personal information when you use Carovia (the
                            “App'). The App is providing users with information
                            about their location relative to toll zones and also
                            tracks history of their visits into these zones..
                          </p>
                        </div>
                      </section>
                      <section
                        title="1. Information We Collect"
                        id="acceptance-of-this-privacy-policy"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            1. Information We Collect
                          </h3>
                          <p className="legal-text">
                            The Carovia App does not collect or store any
                            personal information remotely. All data inputted by
                            users, including vehicle registration details, MOT
                            history, road tax details, and selected charge
                            zones, is stored locally on users' devices.
                          </p>
                          <p className="legal-text">
                            If you do not agree with or you are not comfortable
                            with any aspect of this Privacy Policy, you should
                            immediately discontinue access or use of our
                            Services.
                          </p>
                        </div>
                      </section>
                      <section
                        title="2. How We Use Your Information"
                        id="the-information-we-collect"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            2. How We Use Your Information
                          </h3>
                          <p className="legal-text">
                            As the App does not collect or store personal
                            information remotely, we do not use personal
                            information for any purposes beyond the
                            functionality of the App itself.
                          </p>
                        </div>
                      </section>
                      <section
                        title="3. How We Share Your Information"
                        id="relationships-with-service-providers"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            3. How We Share Your Information
                          </h3>
                          <p className="legal-text">
                            We may share your personal information with third
                            parties in the following circumstances:
                          </p>
                          <ul className="legal-list">
                            <li>
                              With service providers who assist us in operating
                              the App and providing services to you.
                            </li>
                            <li>
                              With law enforcement authorities or other
                              government agencies when required by law or to
                              protect our rights or the rights of others.
                            </li>
                            <li>
                              With your consent or as otherwise disclosed at the
                              time of data collection.
                            </li>
                          </ul>
                        </div>
                      </section>
                      <section
                        title="4. Data Retention"
                        id="how-we-protect-and-store-your-data"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            4. Data Retention
                          </h3>
                          <p className="legal-text">
                            We will retain your personal information for as long
                            as necessary to fulfil the purposes outlined in this
                            Privacy Policy, unless a longer retention period is
                            required or permitted by law.
                          </p>
                        </div>
                      </section>
                      <section title="5. Your Rights" id="childrens-privacy">
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            5. Your Rights
                          </h3>
                          <p className="legal-text">
                            Subject to applicable law, you may have certain
                            rights regarding your personal information,
                            including the right to access, correct, or delete
                            your information. Please contact us using the
                            information provided below to exercise your rights.
                          </p>
                        </div>
                      </section>
                      <section
                        title="6. Changes to this Privacy Policy"
                        id="childrens-privacy"
                      >
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            6. Changes to this Privacy Policy
                          </h3>
                          <p className="legal-text">
                            We may update this Privacy Policy from time to time
                            to reflect changes in our practices or legal
                            requirements. We will notify you of any material
                            changes by posting the updated Privacy Policy within
                            the App.
                          </p>
                        </div>
                      </section>
                      <section title="7. Children" id="childrens-privacy">
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            7. Children
                          </h3>
                          <p className="legal-text">
                            We do not use the App to knowingly solicit data from
                            or market to children under the age of 13. If a
                            parent or guardian becomes aware that his or her
                            child has provided us with information without their
                            consent, he or she should contact us at
                            support@carovia.co.uk. We will delete such
                            information from our files within a reasonable time.
                          </p>
                        </div>
                      </section>
                      <section title="8. Security" id="childrens-privacy">
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            8. Security
                          </h3>
                          <p className="legal-text">
                            Ensuring the confidentiality of your information is
                            a top priority for us. To safeguard your data, we
                            employ physical, electronic, and procedural
                            measures. For instance, we limit access to stored
                            information to authorised employees and contractors
                            who require it for the operation, development, or
                            enhancement of our App. It's important to note that
                            while we strive to implement reasonable security
                            measures, no system can guarantee absolute
                            protection against all potential security breaches.
                          </p>
                        </div>
                      </section>
                      <section title="9. Consent" id="childrens-privacy">
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            9. Consent
                          </h3>
                          <p className="legal-text">
                            By utilising this App, you are giving consent to our
                            processing of your information as delineated in this
                            Privacy Policy, both presently and as revised by us.
                            Processing involves utilising cookies on a
                            computer/handheld device or interacting with
                            information in any manner, including but not limited
                            to collecting, storing, deleting, utilising,
                            merging, and disclosing information. All such
                            activities will occur within the United Kingdom. If
                            you reside outside the United Kingdom, your
                            information will be transferred, processed, and
                            stored there, adhering to United Kingdom privacy
                            standards.
                          </p>
                        </div>
                      </section>
                      <section title="10. Contact Us" id="childrens-privacy">
                        <div
                          width="default"
                          className="privacy-policy-text-container"
                        >
                          <h3 className="privacy-policy-subheading-text">
                            10. Contact Us
                          </h3>
                          <p className="legal-text">
                            If you have any questions or concerns about this
                            Privacy Policy or our data practices, please contact
                            us at support@carovia.co.uk
                          </p>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        );
    }
}

export default PrivacyPolicy;
